$light-shade: #ffffff;

$shade-1: #F5F5F5;
$shade-2: #F0F0F0;
$shade-3: #EBEBEB;

$dark-1: #1B2235;
$dark-2: #20283E;
$dark-3: #2D3958;
$dark-4: #6C748A;

$adme-pink: #FF227A;
$adme-blue: #7B61FF;
$adme-yellow: #F4A50D;
