@import "abstracts/variables";

// fonts

// vendor
@import "vendor";
// video js
@import "~video.js/dist/video-js.css";


body {
  background-color: $shade-1;
  font-family: $base-font-family;
  font-size: 14px;
  height: 100vh;
}
